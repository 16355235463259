import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AngularFireDatabase ,AngularFireObject} from '@angular/fire/database';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
  
  uid:string;
  userAppsRef: AngularFireObject<any>;
  userApps:any = [];
  userInfo:any;
  userInfoRef: AngularFireObject<any>;
  activeApp:any;
  showAddApps:boolean = false;
  constructor(private authService:AuthService,private router: Router,private db: AngularFireDatabase, private sanitizer:DomSanitizer) { }
  appsInContainer:any = [];

  ngOnInit() {

  	this.uid = this.authService.getUid();
  	console.log("uid:"+this.uid);

  	 this.userInfoRef = this.db.object('users/'+this.uid);
    this.userInfoRef.snapshotChanges().subscribe(action => {
      
      this.userInfo = action.payload.val();

      console.log(this.userInfo);
    });

  	 this.userAppsRef = this.db.object('users/'+this.uid+'/activeApps');
     this.userAppsRef.snapshotChanges().subscribe(action => {
      
      console.log("new changes for user apps");

      this.userApps = action.payload.val();
      for(var i=0 ; i< this.userApps.length; i++){
           
            if(this.userApps[i].active){
             this.activeApp = this.userApps[i].app;
            }
      }

      if(this.appsInContainer.length != this.userApps.length){

        console.log("apps in container");
      console.log(this.appsInContainer);

        this.appsInContainer = [];
        for(var i=0 ; i < this.userApps.length; i++){
           
            this.appsInContainer.push({url:this.getSanitizedUrl(this.userApps[i].app.url), index: i});
        }
      }

      

      console.log(this.userApps);
    });
  }

  activateApp(appDtls, type){
  var index = -1;
 	this.showAddApps = false;
      if(this.userApps && this.userApps.length > 0){
        var flag = false;
        
        for(var i=0 ; i<this.userApps.length; i++){
            this.userApps[i]['active'] = false;
            if(this.userApps[i].type == type){
              flag = true;
              index = i;
            }
        }
        if(flag && index > -1){

          this.userApps[index]['active'] = true;
          this.activeApp = this.userApps[index]['app'];
        }
        if(!flag){
          index = 0;
          this.userApps.push({app:appDtls, type:type, active:true});
          this.activeApp = appDtls;
        }
    }
    else{
        index = 0;
        this.userApps = [];
        this.userApps.push({app:appDtls, type:type, active:true});
        this.activeApp = appDtls;
    }
    
    const appsRef = this.db.object('users/'+this.uid+'/activeApps');
    appsRef.set(this.userApps);

    this.loadApp(appDtls, index);
  }

  loadApp(app,index){
  	this.activeApp = app;
  	this.showAddApps = false;

    for(var i=0 ; i<this.userApps.length; i++){
            this.userApps[i]['active'] = false;

    }
    this.userApps[index]['active'] = true;
    const appsRef = this.db.object('users/'+this.uid+'/activeApps');
    appsRef.set(this.userApps);
    console.log("loading app");
  }
  getSanitizedUrl(url){
  	return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  deleteApp(index){
  	this.userApps.splice(index, 1);

   

    for(var i=0 ; i<this.userApps.length; i++){
            this.userApps[i]['active'] = false;

    }

    if(index > 0){
      this.activeApp = this.userApps[index - 1]['app'];
      this.userApps[index - 1]['active'] = true;
    }

    const appsRef = this.db.object('users/'+this.uid+'/activeApps');
    appsRef.set(this.userApps);
  }

  showApps(){
  	this.showAddApps = true;
  }

}
