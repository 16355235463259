import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: any = null;

constructor(private _firebaseAuth: AngularFireAuth, private router: Router) { 
      this.user = _firebaseAuth.authState;

      this.user.subscribe(
        (user) => {
          if (user) {
            this.userDetails = user;
            console.log("user details");
            console.log(this.userDetails);
            if(this.router.url == '/login'){
                this.router.navigate(['/']);
            } 
          }
          else{
            this.userDetails = null;
          }
        }
      );
  }
  signInRegular(email:any, password:any) {
   	const credential = firebase.auth.EmailAuthProvider.credential( email, password );
	return this._firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  }
  isLoggedIn() {
  	if (this.userDetails) {
      return true;
   	 } else {
      return false;
    	}
  }
  logout() {
	    this._firebaseAuth.auth.signOut()
	    .then((res) => this.router.navigate(['login']));
   }
   getUid(){
    if(this.userDetails && this.userDetails.uid){
      return this.userDetails.uid;
    }
    else{
      return null;
    }
   }
	
}