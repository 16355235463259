
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import * as firebase from 'firebase/app';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

import { HttpModule } from '@angular/http';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ContainerComponent } from './container/container.component';


import { AuthService } from './services/auth.service';
import { AuthGuard } from 'app/services/auth-guard.service';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        ContainerComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebase, 'mtt-portal'),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        FormsModule,
        HttpModule
    ],
    providers: [
        AuthService,
        AuthGuard
    ],
    entryComponents: [
        
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
