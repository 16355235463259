// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    firebase: {
            apiKey: "AIzaSyB9NXOszAMFI21Qv_A9iVOOzc3UMnj9kWs",
		    authDomain: "mtt-portal.firebaseapp.com",
		    databaseURL: "https://mtt-portal.firebaseio.com",
		    projectId: "mtt-portal",
		    storageBucket: "mtt-portal.appspot.com",
		    messagingSenderId: "703729081243"
    }
};
