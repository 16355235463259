import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AngularFireDatabase ,AngularFireObject} from '@angular/fire/database';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  uid:string;
  userInfo:any;
  userInfoRef: AngularFireObject<any>;
  userAppsRef: AngularFireObject<any>;
  userApps:any = [];
  constructor(private authService:AuthService,private router: Router,private db: AngularFireDatabase) { }

  ngOnInit() {
  	this.uid = this.authService.getUid();
  	console.log("uid:"+this.uid);
  	this.userInfoRef = this.db.object('users/'+this.uid);
    this.userInfoRef.snapshotChanges().subscribe(action => {
      
      this.userInfo = action.payload.val();

      console.log(this.userInfo);
    });

    this.userAppsRef = this.db.object('users/'+this.uid+'/activeApps');
    this.userAppsRef.snapshotChanges().subscribe(action => {
      
      this.userApps = action.payload.val();

      console.log(this.userApps);
    });
  }

  activateApp(appDtls, type){
 
    if(this.userApps && this.userApps.length > 0){
        var flag = false;
        var index = -1;
        for(var i=0 ; i<this.userApps.length; i++){
            this.userApps[i]['active'] = false;
            if(this.userApps[i].type == type){
              flag = true;
              index = i;
            }
        }
        if(flag && index > -1){
          this.userApps[index]['active'] = true;
        }
        if(!flag){
          this.userApps.push({app:appDtls, type:type, active:true});
        }
    }
    else{
        this.userApps = [];
        this.userApps.push({app:appDtls, type:type, active:true});
    }
    const appsRef = this.db.object('users/'+this.uid+'/activeApps');
    appsRef.set(this.userApps);
    this.router.navigate(['container'])
  }

}
