
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ContainerComponent } from './container/container.component';

import { AuthGuard } from './services/auth-guard.service';

const appRoutes: Routes = [
  
    { path:'login', component:LoginComponent },
    { path:'', canActivate: [AuthGuard], component:HomeComponent },
    { path:'container', canActivate: [AuthGuard], component:ContainerComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    providers: [
        
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
